import { createSlice } from "@reduxjs/toolkit";

const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        items: []
    }, 
    reducers: {
        addPayDetail : (state, action) => {
            state.items.push(action.payload);
        }
    }
});

export const { addPayDetail } = paymentSlice.actions;

export default paymentSlice.reducer;