import { createSlice } from "@reduxjs/toolkit";

const blogSlice = createSlice({
    name: "blog",
    initialState: {
        items: []
    }, 
    reducers: {
        addItem: (state, action) => {
            // mutating the state here.
            state.items.push(action.payload);
        }
    }
});

export const { addItem } = blogSlice.actions;

export default blogSlice.reducer;